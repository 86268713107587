/* accordion.css */
.accordion {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  padding: 0 var(--form-spacing);
}

.accordion-item {
  margin: var(--form-spacing) 0;
  border-radius: var(--input-radius);
  transition: var(--primaryTransition);
  /* border: var(--); */
}

.accordion-item:last-child {
  /* border: var(--dynamicIconBorder); */
}

.accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px;
  background: var(--boxGradient);
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: var(--input-radius);
}

.accordion-title {
  font-family: var(--font-family-sans-serif);
  font-weight: var(--font-weight-paragraph);
  color: var(--maintext-color);
}

.accordion-item svg {
  color: var(--secondary-color);
  transition: transform 0.3s ease;
}

.accordion-content {
  padding: 20px;
  color: var(--maintext-color);
  font-size: 1rem;
  line-height: 1.5;
  font-weight: var(--font-weight-paragraph);
}

.accordion-content:not(.hide-in-dark) {
  background-color: var(--transparentFade-bg1);
}

.accordion-content:not(.hide-in-light) {
  background-color: var(--transparentFade-bg);
}

.accordion-item svg {
  transform: rotate(0deg);
}

.accordion-item .accordion-header .accordion-icon.open {
  transform: rotate(180deg);
}

.faq-q {
border: var(--borderBeforeHover);
}