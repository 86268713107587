/* testimonials.css */
#testimonialSlider {
  overflow-x: hidden;
}

.slick-list {
  margin: 0 -15px; /* Adjust margin to account for padding on slick-slide */
}

.slick-dots {
  bottom: -25px; /* Adjust the position of the dots */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.slick-dots li {
  margin: 0 5px; /* Space between dots */
}

.slick-dots li button:before {
  font-size: 12px; /* Size of the dots */
  color: var(--primary-color); /* Default dot color */
  opacity: 0.5; /* Default opacity */
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.slick-dots li.slick-active button:before {
  color: var(--transparentFade-bg1); /* Active dot color */
  opacity: 1; /* Full opacity for active dot */
  transform: scale(1.2); /* Slightly enlarge the active dot */
}

/* Additional hover effect (optional) */
.slick-dots li button:hover:before {
  opacity: 0.8;
  transform: scale(1.1);
}

@media (min-width: 1024px) {
  .testimonials-content h2 {
    padding-right: var(--large-padding);
  }
}

@media (max-width: 768px) {
  .testimonials {
    overflow-x: hidden;
  }
}
