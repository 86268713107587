/* pricing-box.css */
.pricing-box {
    margin: 20px;
    padding: 20px;
    border-radius: var(--border-radius);
    transition: var(--primaryTransition); 
  }
  
  .pricing-box:hover {
    box-shadow: var(--boxShadow1); /* Apply hover effect with shadow */
  }
  
  .pricing-title {
    margin-bottom: var(--primary-padding);
  }
  
  .pricing-description {
    margin-bottom: var(--primary-padding);
  }
  
  .pricing-price {
    font-size: var(--font-size-h1); /* Use larger font for the price */
    margin-bottom: var(--primary-padding);
  }
  
  .price-amount {
    font-weight: var(--font-weight-bold);
  }
  
  .payment-type {
    font-size: var(--font-size-p);
    margin-left: 5px;
  }
  
  .pricing-features {
    list-style-type: none;
    padding: 0;
    margin-bottom: var(--primary-padding);
  }
  
  .feature-item {
    font-size: var(--font-size-p-small);
    margin-bottom: 10px;
  }

  .feature-item img {
    margin-right: 5px;
  }

  .featured-plan {
    transform: scale(1.1);
  }

  .pricing-button {
  }