/* circle-checkbox.css */

.circle-checkbox__label {
    cursor: pointer;
    width: 34px; /* Adjust size */
    height: 34px; /* Adjust size */
    background: var(--transparentFade-bg1);
    border: 1px var(--secondary-bg);
    border-radius: 50px; /* Rounded shape */
    position: relative;
    transition: background-color 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
}