.list-item {
    list-style: none;
}

.contact-info-item {
    margin-top: var(--primary-padding);
}

@media (min-width: 1024px) {
    .left-on-desktop {
            align-items: start!important;
    }    

    .small-top-space-desktop {
        padding-top: 12px;
    }
}

@media (max-width: 1024px) {
    .left-on-desktop {
        align-items: center!important;
    }

    .left-on-desktop h6 {
        padding-left: 15px;
    }
}