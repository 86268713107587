/* fields.css */
input,
textarea {
    padding: 15px 20px;
    /* background-color: var(--transparentFade-bg); */
    background: var(--boxGradient);
    color: var(--maintext-color);
    border: var(--borderBeforeHover);
    transition: 1s!important;
    font-size: var(--font-size-p-small);
    font-family: var(--font-family-sans-serif);
    outline: none;
    border-radius: var(--input-radius);
    flex-grow: 1;
    text-transform: uppercase;
    letter-spacing: var(--letter-spacing-normal);
}

textarea {
    height: 150px;
}

/* input:focus, textarea:focus {
    border: 2px solid var(--primary-color);
    box-shadow: var(--boxShadow1);
} */

/* Set autofill background to match normal background */
input:-webkit-autofill,
textarea:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active {
    /* background: var(--background-color) !important;  */
    -webkit-background-clip: text;
    -webkit-text-fill-color: var(--maintext-color);
    transition: background-color 5000s ease-in-out 0s; /* Keep the transition */
}
