.process-box {
  border-radius: var(--border-radius);
  min-height: 100px;
}

.process-box h3 {
    font-size: calc(var(--font-size-h3) * 1.5);
    text-align: right;
}

.step-container {
  padding: 0;
  margin: 0;
}

@media (min-width: 768px) {
  .process-box h3 {
    text-align: right;
}
}

@media (max-width: 768px) {
  .process-box {
    padding: var(--primary-padding);
  }

  .process-box h3 {
    text-align: center;
}

.process-box img {
  margin: calc(var(--primary-padding) * 1.1);
}
}