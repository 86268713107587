/* footer.css */
.footer {
  position: relative; /* Default positioning */
  z-index: 1000; /* Ensure it's above content */
  text-align: center; /* Center text */
}

.sticky-footer {
  position: fixed; /* Make it sticky */
  bottom: 0; /* Stick to the bottom of the viewport */
  left: 0; /* Align to the left */
  width: 100%; /* Full width */
}

.relative-footer {
  position: relative; /* Normal document flow */
}

.footer-menu {
  list-style: none;
  gap: 20px;
}

.footer-menu a {
  color: var(--maintext-color);
  text-decoration: none;
  transition: var(--transition);
}

/* .footer-menu a:hover {
  text-decoration: underline;
} */

#footer-copyright {
  /* color: var(--transparentFade-bg2); */
}