/* cursor.css */
.cursor {
  position: fixed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: var(--darkBG-primary-color); /* Mask color */
  pointer-events: none; /* Ensures it doesn't block interactions */
  mix-blend-mode: difference; /* Creates the masking/inversion effect */
  transform: translate(-50%, -50%);
  z-index: 9999; /* Keeps it above all elements */
  transition: background-color 0.3s ease;
}

.cursor.hover-text {
  background-color: var(--primary-color); /* No color change, same for consistency */
}

.invert-on-hover:hover {
  transition: color 0.3s ease; /* Smooth color transition */
}