/* styles.css - global theme styles */
@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700;800&display=swap');
@font-face {
  font-family: 'Lastica';
  src: url('../../../public/assets/fonts/Lastica-1760012/Lastica.otf') format('opentype'),
       url('../../../public/assets/fonts/Lastica-1760012/Lastica.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

:root {
  --head-foot-container-width: 97%;
  --container-width: 82%;
  --section-height: 100vh;
  --smaller-padding: 15px;
  --primary-padding: 20px;
  --large-padding: calc(var(--primary-padding) * 3);
  --mobile-breakpoint: 768px;

  /* fonts */
  /* Font Family */
  --font-family-sans-serif: "Helvetica Neue", "Mukta", sans-serif;
  --font-family-header: 'Lastica', sans-serif;

/* Font Sizes */
--font-size-h1: clamp(2rem, 4.5vw, 3rem); /* Adjusted for better sizing on small screens */
--font-size-h2: clamp(2rem, 3.5vw, 3rem); /* Adjusted for better sizing on small screens */
--font-size-h3: clamp(1.3rem, 1vw, 1.5rem); /* Example for H3 */
--font-size-h4: clamp(1.5rem, 3vw, 1.7rem); /* Increase the minimum size for mobile */
--font-size-h5: clamp(0.9rem, 2vw, 1.2rem); /* Example for H5 */
--font-size-h6: clamp(0.8rem, 1.2vw, 1rem); /* Example for H6 */
--font-size-p: clamp(1rem, 2vw, 1.1rem); /* Example for paragraphs */
--font-size-p-xSmall: clamp(0.8rem, 1.5vw, .8rem); /* Example for extra small paragraphs */
--font-size-p-small: clamp(0.95rem, 2vw, 1rem); /* Example for small paragraphs */
--font-size-p-large: clamp(1rem, 2vw, 1.4rem); /* Example for large paragraphs */
--font-size-p-xLarge: clamp(1.2rem, 2.5vw, 1.8rem);
  /* --emphasized-hero-header-font-size: clamp(3.5rem, 6vw, 9rem); */
  /* --section-header-font-size: clamp(3.75rem, 9vw, 4.3rem); */

  /* Line Heights */
  --line-height-heading: 1.1;
  --line-height-paragraph: 1.5;

  /* Font Weights */
  --font-weight-regular: 300;
  --font-weight-bold: 900;
  --font-weight-paragraph: 100;

  /* Letter Spacing */
  --letter-spacing-narrow: 0.15em;
  --letter-spacing-normal: 0.2em;
  --letter-spacing-wide: 0.25em;

  --border-radius: 50px;
  --input-radius: 10px;

  --form-spacing: 15px; /* Define a variable for spacing */
  /* colors */
  --darkBG-primary-color: #5e76f6;
  --lightBG-primary-color: #1c1c1c;
  --darkBG-maintext-color: #f4f4f4;
  --lightBG-maintext-color: #2b2b2b;
  --darkBG-background-color: #0e0e0e;
  --lightBG-background-color: #efefef;
  --darkBG-secondary-bg: #1d1d1e;
  --lightBG-secondary-bg: #c5c5c5;
  --darkBG-tertiary-bg: #2b2b2b;
  --lightBG-tertiary-bg: #e0e0e0;
  --darkBG-transparentFade-bg: rgba(44, 44, 44, 0.231);
  --lightBG-transparentFade-bg: rgba(21, 21, 21, 0.057);
  --darkBG-transparentFade-bg1: rgba(78, 78, 78, 0.338);
  --darkBG-transparentFade-bg2: rgba(80, 80, 80, 0.646);
  --lightBG-transparentFade-bg: rgba(224, 223, 221, 0.4);
  --lightBG-transparentFade-bg1: rgba(207, 207, 207, 0.657);
  --lightBG-transparentFade-bg2: rgba(199, 199, 199, 0.646);

  --everdark-color1: var(--darkBG-background-color);
  --everdark-color2: var(--lightBG-maintext-color);
  --everlight-color1: var(--lightBG-background-color);

  --light-shadow1: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  --primaryTransition: 1s;

  /* buttons and icons */
  --buttonandIconborderStyle: 2px solid;
  --borderBeforeHover: var(--buttonandIconborderStyle) var(--secondary-bg);
  --darkBG-complex-box-shadow: 0 0 60px 15px var(--primary-color), 0 0 100px 50px var(--primary-color), 0 0 200px 100px var(--primary-color);
  --lightBG-complex-box-shadow: none;
  --darkBG-dropshadow1: drop-shadow(0 0 6px var(--primary-color)) drop-shadow(0 0 8px var(--primary-color)) drop-shadow(0 3px 12px var(--primary-color));
  --lightBG-dropshadow1: none;
  --darkBG-dropshadow2: drop-shadow(0 0 3px var(--primary-color)) 
  drop-shadow(0 0 6px var(--primary-color)) 
  drop-shadow(0 0 10px var(--primary-color)) 
  drop-shadow(0 0 15px var(--primary-color)) 
  drop-shadow(0 0 25px var(--primary-color));
  --darkBG-gradientBG1: linear-gradient(145deg, var(--background-color), var(--secondary-bg));
  --darkBG-gradientBG2: linear-gradient(145deg, var(--background-color), var(--tertiary-bg));
  --lightBG-gradientBG1: linear-gradient(145deg, var(--background-color), var(--secondary-bg));
  --lightBG-gradientBG2: linear-gradient(145deg, var(--background-color), var(--tertiary-bg));
  --darkBG-gradientBG3: linear-gradient(145deg, var(--background-color), var(--thirdary-bg));
  --lightBG-gradientBG3: linear-gradient(145deg, var(--background-color), var(--thirdary-bg));
  --darkBG-boxGradient: var(--gradientBG1);
  --lightBG-boxGradient: var(--gradientBG2);
  --lightBG-dropshadow2: none;
  --darkBG-dropshadow3: drop-shadow(0 0 .6px var(--primary-color)) drop-shadow(0 0 .6px var(--primary-color)) drop-shadow(0 0 1px var(--primary-color)) drop-shadow(0 0 1px var(--primary-color)) drop-shadow(0 0 2px var(--primary-color));
  --lightBG-dropshadow3: none;
  /* --darkBG-boxShadow1: ;
  --lightBG-boxShadow1: ; */
  --lightBG-textShadow1: none;
  --darkBG-textShadow1: 0 0 2.5px var(--primary-color), 0 0 5px var(--primary-color), 0 0 10px var(--primary-color), 0 0 20px var(--primary-color), 0 0 30px ;
  --darkBG-dynamicIconBorder: var(--buttonandIconborderStyle) var(--primary-color);
  --lightBG-dynamicIconBorder: var(--buttonandIconborderStyle) var(--secondary-bg);
  --darkBG-dynamicBorderBoxShadow: var(--boxShadow1);
  --lightBG-dynamicBorderBoxShadow: var(--light-shadow1);
  --darkBG-dynamicIconBG: transparent;
  --lightBG-dynamicIconBG: var(--gradientBG1);
  --darkBG-dynamicIconHover: var(--buttonandIconborderStyle) var(--primary-color);
  --lightBG-dynamicIconHover: var(--buttonandIconborderStyle) none;
  --darkBG-dynamicButtonHover: var(--darkBG-dynamicIconHover);
  --lightBG-dynamicButtonHover: var(--buttonandIconborderStyle) var(--everdark-color1);
}

/* Basic Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* overflow-x: hidden; */
}

body {
  background-color: var(--background-color);
  color: var(--maintext-color);
  font-family: var(--font-family-sans-serif);
  font-size: var(--font-size-p);
  line-height: var(--line-height-paragraph);
  letter-spacing: var(--letter-spacing-normal);
  text-transform: uppercase;
  scroll-behavior: smooth;
  /* margin: auto 0; */
}

div, img, button, input, svg, section :not(.complex-shadow) {
  z-index: 4;
}

h1,
h2,
h3,
h4,
h5,
h6,
.navMenu {
  font-family: var(--font-family-header);
  line-height: var(--line-height-heading);
  letter-spacing: var(--letter-spacing-wide);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: var(--font-weight-bold);
}

p {
  font-weight: var(--font-weight-paragraph);
}

.navMenu {
  font-weight: var(--font-weight-paragraph);
}

.font-weight-regular {
  font-weight: var(--font-weight-regular);
}

.font-weight-bold {
  font-weight: var(--font-weight-bold);
}

.font-weight-paragraph {
  font-weight: var(--font-weight-paragraph);
}

h2, h4 {
  /* color: var(--everdark-color1); */
}

h1, .h1 {
  font-size: var(--font-size-h1);
}

h2, .h2 {
  font-size: var(--font-size-h2);
}

h3, .h3 {
  font-size: var(--font-size-h3);
}

h4, .h4 {
  font-size: var(--font-size-h4);
}

h5, .h5 {
  font-size: var(--font-size-h5);
}

h6, .h6 {
  font-size: var(--font-size-h6);
}

p, .p {
  font-size: var(--font-size-p);
  line-height: var(--line-height-paragraph);
  font-weight: var(--font-weight-paragraph);
}

li {
  font-weight: var(--font-weight-regular);
}

a {
  color: var(--maintext-color);
  text-decoration: none;
}

.p-xSmall {
  font-size: var(--font-size-p-xSmall);
}

.p-small {
  font-size: var(--font-size-p-small);
}

.p-large {
  font-size: var(--font-size-p-large);
}

.p-xLarge {
  font-size: var(--font-size-p-xLarge);
}

.absolute {
  position: absolute;
}

.top {
  top: 0;
}

.bottom {
  bottom: 0;
}

.right {
  right: 0;
}

.left {
  left: 0;
}

.w10 {
  width: 10%;
}

.w20 {
  width: 20%;
}

.w30 {
  width: 30%;
}

.w40 {
  width: 40%;
}

.w50 {
  width: 50%;
}

.w60 {
  width: 60%;
}

.w70 {
  width: 70%;
}

.w80 {
  width: 80%;
}

.w90 {
  width: 90%;
}

.w100 {
  width: 100%;
}

.primaryTransition {
  transition: var(--primaryTransition);
}

.longTransition {
  transition: 3s;
}

.text-shadow-for-dark {
  text-shadow: var(--text-shadow);
  transition: var(--primaryTransition);
  color: var(--everdark-color1);
  letter-spacing: 0.1em;
}

.text-shadow-for-dark-hover:hover {
  text-shadow: var(--text-shadow);
  transition: var(--primaryTransition);
  color: var(--everdark-color1)!important;
  /* letter-spacing: 0.1em; */
}

::selection {
  background: transparent;
  color: var(--darkBG-primary-color);
  transition: var(--primaryTransition);
}

.bold {
  font-weight: var(--font-weight-bold);
}

.primary-color {
  color: var(--primary-color);
}

.everdark-color1 {
  color: var(--everdark-color1);
}

.everdark-color2 {
  color: var(--everdark-color2);
}

.primary-clr-text-outline {
  -webkit-text-stroke: 2px var(--primary-color);
  /* box-shadow: var(--dropshadow1); */
}

.space {
  padding: var(--primary-padding);
}

.box-gap {
  gap: 30px;
}

.smaller-space {
  padding: var(--smaller-padding);
}

.larger-space {
  padding: var(--large-padding);
}

.bottom-space {
  padding-bottom: var(--primary-padding);
}

.smaller-bottom-space {
  padding-bottom: var(--smaller-padding);
}

.larger-bottom-space {
  padding-bottom: var(--large-padding);
}

.top-space {
  padding-top: var(--primary-padding);
} 

.smaller-top-space {
  padding-top: var(--smaller-padding);
}

.larger-top-space {
  padding-top: var(--large-padding);
}

.right-space {
  padding-right: var(--primary-padding);
}

.smaller-right-space {
  padding-right: var(--smaller-padding);
}

.larger-right-space {
  padding-right: var(--large-padding);
}

.left-space {
  padding-left: var(--primary-padding);
}

.smaller-left-space {
  padding-left: var(--smaller-padding);
}

.larger-left-space {
  padding-left: var(--large-padding);
}

.section-gap {
  gap: var(--section-gap);
}

/* --------------------------------------------------- */

.none {
  display: none;
}

.flex {
  display: flex;
}

.justify-left {
  justify-content: flex-start;
}

.justify-right {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.item-stretch {
  align-items: stretch !important;
}

.item-align-center {
  align-items: center;
}

.item-align-start {
  align-items: start;
}

.item-align-end {
  align-items: end;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left; /* Align text left */
}

.text-right {
  text-align: right; /* Align text right */
}

.self-center {
  align-self: center;
}

.self-right {
  align-self: flex-end;
}

.self-left {
  align-self: flex-start;
}

.margin-center {
  margin: 0 auto;
}

.full-height {
  min-height: var(--section-height);
}

.fluid-container {
  width: 100%;
}

.fif-container {
  width: 5%;
}

.column {
  flex-direction: column;
}

.column-reverse {
  flex-direction: column-reverse;
}

.wrap {
  flex-wrap: wrap;
}

.grow {
  flex-grow: 1;
}

.shrink {
  flex-shrink: 1;
}

.reverse {
  flex-direction: row-reverse;
}

.no-side-scroll {
  overflow-x: hidden;
}

/* Dark Mode Styles */
.dynamic-border-effect {
  box-shadow: var(--boxShadow1); 
}

.styled-icon .dynamic-border-effect {
  border: var(--dynamicIconBorder);
}

.styled-button .dynamic-hover-border-effect, 
.box-link .dynamic-hover-border-effect:hover {
  border: var(--dynamicIconBorder) !important;
}

.dynamic-hover-border-effect {
  transition: border 0.5s, box-shadow 0.5s, transform 0.5s; /* Add transform for scaling */
}

.dynamic-hover-border-effect:hover {
  border: var(--dynamicIconHover) !important;
  box-shadow: var(--boxShadow1) !important; /* Neon glow effect */
}


.dynamic-active-border-effect.active {
  border: var(--dynamicIconBorder) !important;
  box-shadow: var(--boxShadow1) !important;
}

.dynamic-focus-border-effect:focus {
  border: var(--dynamicIconBorder);
  box-shadow: var(--boxShadow1); /* Neon glow effect */
}

.invert:hover {
  filter: invert(1);
}

::selection {
  background: transparent;
  color: var(--darkBG-primary-color);
}

@media (min-width: 1024px) {
  :root {
    --section-gap: 60px; /* Define a variable for the gap between sections */
  }
  
  .sticky-section {
    height: fit-content;
    position: sticky;
    top: 0;
}
}

@media (min-width: 768px) {

  .container {
    width: var(--container-width);
  }

  .header-foot-container {
    width: var(--head-foot-container-width);
  }

  .morethan-half-container {
    width: 60%;
  }

  .lessthan-half-container {
    width: 40%;
  }

  .half-column {
    width: 50%;
  }

  .small-screens {
    display: none;
  }

  .negative-top {
    margin-top: 0px!important;
  }

  .desktop-left-space {
    margin-left: var(--smaller-padding);
  }
}

@media (max-width: 1024px) {
  :root {
    /* --container-width: 85%; */
    --section-gap: 20px; 
  }

  .responsive {
    flex-direction: column;
  }

  .responsive-row {
    flex-direction: row;
  }

  .responsive-reverse {
    flex-direction: column-reverse;
  }

    .responsive-left {
    text-align: left;
    justify-content: left;
  }

  .responsive-center {
    text-align: center!important;
    justify-content: center!important;
  }

  .responsive-right {
    text-align: right;
    justify-content: right;
  }

  .responsive-container {
    width: var(--container-width);
  }

  .responsive-spacing {
    padding: var(--primary-padding) 0;
  }

  .w10, .w20, .w30, .w40, .w50, .w60, .w70, .w80, .w90, .w100 {
    width: var(--container-width);
  }
}

@media (max-width: 768px) {
  /* h2 {
    font-size: calc(var(--font-size-h2) * 4); 
  } */

  .header-foot-container {
    width: calc(var(--head-foot-container-width) - 5%);
  }

  .responsive {
    flex-direction: column;
  }

  .responsive-reverse {
    flex-direction: column-reverse;
  }

  .responsive-center {
    text-align: center;
    justify-content: center;
  }

  .responsive-container {
    width: var(--container-width);
  }

  .responsive-spacing {
    padding: var(--primary-padding) 0;
  }

  .container {
    width: calc(var(--container-width) + 10%)
  }

  .self-right {
    align-self: center;
  }
  
  .self-left {
    align-self: center;
  }

  .large-screens {
    display: none;
  }

  .morethan-half-container,
  .lessthan-half-container,
  .half-column {
    width: var(--container-width);
  }

  .small-logo img {
    margin-left: 30px;
  }
}