/* Preloader.css */
.preloader {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--darkBG-background-color);
    z-index: 9999;
    overflow: hidden;
    transition: opacity 1.5s ease-out; /* Smooth transition for fade-out */
  }
  
  .load-logo {
    animation: roll-in 2s ease-in-out;
  }
  
  .fade-out {
    opacity: 0;
    pointer-events: none; /* Prevent interaction when fading out */
  }
  
  @keyframes roll-in {
    0% {
      transform: translateX(-100vw) rotate(-360deg);
    }
    100% {
      transform: translateY(0) rotate(0);
    }
  }