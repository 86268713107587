.about-menu-label {
    padding-left: 8px;
}

.about-menu p {
    padding: 5px;
}

.about-menu img {
    margin-left: -5px;
}