/* social-icons.css */
.social-icons .social-icon {
    gap: 10px;
}

.social-icon a {
    cursor: pointer;
    width: 34px; /* Adjust size */
    height: 34px; /* Adjust size */
    /* background: var(--transparentFade-bg1); */
    border-radius: 50px; /* Rounded shape */
    position: relative;
    transition: background-color 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--maintext-color);
    transition: var(--transition);
}

/* .social-icon a:hover {
    color: var(--primary-color);
} */

/* .social-icon svg {
    font-size: 15px;
} */