/* menu.css */
:root {
  --font-size-navMenu: clamp(1.7rem, 2.8vw, 3rem);
}

.menu-fontSize {
  font-size: var(--font-size-navMenu);
}

/* Container for the entire menu */
.menu-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  width: 100%;
  background: var(--background-color);
  position: fixed;
  top: 0;
  left: 0;
  transition: transform 0.3s ease;
  transform: translateY(-100%); /* Hide initially */
}

.menu-container.show {
  transform: translateY(0); /* Show menu when open */
}

/* Content inside the menu */
.menu-content {
  flex-grow: 1;
  /* width: 100%; */
}

/* List of menu items */
.header-menu-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* Flex container for menu items */
.header-menu-list.flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}

@media (min-width: 768px) {
.header-menu-list {
  padding-left: 30px;
}
}