/* hamburger.css */

.hamburger-menu {
    width: 25px;
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    cursor: pointer;
    transition: transform 0.3s ease; /* Smooth transition for transform */

}

.hamburger-menu .line {
    height: 0.5px; /* Adjust height for better visibility */
    background-color: var(--maintext-color);
    transition: all 0.3s ease; /* Transition for all properties */
    
}

.hamburger-menu .big {
    width: 100%;
}

.hamburger-menu .little {
    width: 65%; /* Default width */
}

/* Change lines to X when checked */
.hamburger-toggle:checked + .hamburger-menu .big:first-child {
    transform: rotate(45deg); /* Rotate the first big line */
    position: relative; /* Positioning for overlapping */
    top: 10px; /* Adjust position */
}

.hamburger-toggle:checked + .hamburger-menu .big:last-child {
    transform: rotate(-45deg); /* Rotate the second big line */
    position: relative; /* Positioning for overlapping */
    top: -10px; /* Adjust position */
}

.hamburger-toggle:checked + .hamburger-menu .little {
    opacity: 0; /* Hide the little line */
}

/* Make the little line 100% on hover */
.hamburger-menu:hover .little {
    width: 100%; /* Expand to 100% on hover */
}

@media (max-width: 768px) {
    .hamburger-menu {
        width: 27px;
        height: 32px;
    }
}