/* animations.css */
:root {
    --transition: 0.4s;
}

.hover-scale {
    transition: var(--transition);
}

.hover-scale:hover {
    transform: scale(1.05);
    transition: var(--transition);
}

@media (prefers-reduced-motion: no-preference) {

@keyframes hover-scale-and-back {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

.hover-scale-and-back {
    animation: hover-scale-and-back 1s infinite;
}

.flip-diagonal-1-bl:hover path {
    -webkit-animation: flip-diagonal-1-bl 1s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
    animation: flip-diagonal-1-bl 1s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}

/* Keyframe for scale-in animation */
@keyframes scaleIn {
  0% {
    opacity: 0;
    transform: scale(0.8); /* Start smaller and with lower opacity */
  }
  100% {
    opacity: 1;
    transform: scale(1); /* End at original scale */
  }
}

.scale-in {
  opacity: 0;
  animation: scaleIn 0.5s ease forwards;
}

/* Keyframe for scale-out animation */
@keyframes scaleOut {
  0% {
    opacity: 1;
    transform: scale(1); /* Start at full size */
  }
  100% {
    opacity: 0;
    transform: scale(0.8); /* Shrink down and fade out */
  }
}

.scale-out {
  opacity: 1;
  animation: scaleOut 0.5s ease forwards;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-10-6 23:8:44
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flip-diagonal-1-bl
 * ----------------------------------------
 */
 @-webkit-keyframes flip-diagonal-1-bl {
    0% {
        -webkit-transform: rotate3d(1, 1, 0, 0deg) scale(1); /* Starting state */
        transform: rotate3d(1, 1, 0, 0deg) scale(1);
        transform-origin: center;
    }
    50% {
        -webkit-transform: rotate3d(1, 1, 0, -90deg) scale(1); /* Keep scale at 1 to prevent stretch */
        transform: rotate3d(1, 1, 0, -90deg) scale(1);
        transform-origin: center;
    }
    100% {
        -webkit-transform: rotate3d(1, 1, 0, -180deg) scale(1); /* Ending state with no scale change */
        transform: rotate3d(1, 1, 0, -180deg) scale(1);
        transform-origin: center;
    }
}
@keyframes flip-diagonal-1-bl {
    0% {
        -webkit-transform: rotate3d(1, 1, 0, 0deg) scale(1); /* Starting state */
        transform: rotate3d(1, 1, 0, 0deg) scale(1);
        transform-origin: center;
    }
    50% {
        -webkit-transform: rotate3d(1, 1, 0, -90deg) scale(1); /* Keep scale at 1 to prevent stretch */
        transform: rotate3d(1, 1, 0, -90deg) scale(1);
        transform-origin: center;
    }
}

.flip-scale-up-hor:hover {
    -webkit-animation: flip-diagonal-1-bl 0.5s linear both;
            animation: flip-diagonal-1-bl 0.5s linear both;
}


/* ----------------------------------------------
 * Generated by Animista on 2024-10-11 0:45:57
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flip-scale-up-hor
 * ----------------------------------------
 */
 @-webkit-keyframes flip-scale-up-hor {
    0% {
      -webkit-transform: scale(1) rotateX(0);
              transform: scale(1) rotateX(0);
    }
    50% {
      -webkit-transform: scale(2.5) rotateX(-90deg);
              transform: scale(2.5) rotateX(-90deg);
    }
    100% {
      -webkit-transform: scale(1) rotateX(-180deg);
              transform: scale(1) rotateX(-180deg);
    }
  }
  @keyframes flip-scale-up-hor {
    0% {
      -webkit-transform: scale(1) rotateX(0);
              transform: scale(1) rotateX(0);
    }
    50% {
      -webkit-transform: scale(2.5) rotateX(-90deg);
              transform: scale(2.5) rotateX(-90deg);
    }
    100% {
      -webkit-transform: scale(1) rotateX(-180deg);
              transform: scale(1) rotateX(-180deg);
    }
  }  

  @keyframes shakeRight {
    0% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(5px);
    }
    50% {
      transform: translateX(0);
    }
    75% {
      transform: translateX(5px);
    }
    100% {
      transform: translateX(0);
    }
  }

  .shakeRight {
    animation: shakeRight 0.5s infinite;
  }

  /* intersectionObserver.css */
.intersection-observer-wrapper {
  transition: opacity 0.5s ease, transform 0.5s ease;
  opacity: 0; /* Start hidden */
}

/* On-scroll animation classes */
.spin-in {
  animation: spinIn 0.5s ease forwards;
}

.spin-out {
  animation: spinOut 0.5s ease forwards;
}

@keyframes spinIn {
  from {
      opacity: 0;
      transform: rotate(-90deg);
  }
  to {
      opacity: 1;
      transform: rotate(0deg);
  }
}

@keyframes spinOut {
  from {
      opacity: 1;
      transform: rotate(0deg);
  }
  to {
      opacity: 0;
      transform: rotate(90deg);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(40px); /* Starts slightly below */
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-40px); /* Starts slightly above */
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-down {
  opacity: 0;
  animation: fadeInDown 0.5s ease forwards;
}

.fade-in {
  opacity: 0;
  animation: fadeIn 0.5s ease forwards;
}

.fade-in-up {
  opacity: 0;
  animation: fadeInUp 0.5s ease forwards;
}

.fade-out {
  opacity: 0;
  transition: opacity 0.5s ease;
}

@keyframes sectionFadeOutUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  30% {
    opacity: 0.8;
    transform: translateY(-20px);
  }
  60% {
    opacity: 0.4;
    transform: translateY(-60px);
  }
  100% {
    opacity: 0;
    transform: translateY(-120px);
  }
}

.section-fade-out-up {
  opacity: 1;
  animation: sectionFadeOutUp 2s ease-out forwards; /* Extended duration for smooth scrolling effect */
}

@keyframes sectionFadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-120px); /* Start higher up */
  }
  40% {
    opacity: 0.4;
    transform: translateY(-60px);
  }
  70% {
    opacity: 0.8;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* End at original position */
  }
}

.section-fade-in-down {
  opacity: 0;
  animation: sectionFadeInDown 2s ease-out forwards; /* Same duration for consistency */
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.fade-in-right {
  opacity: 0;
  animation: fadeInRight 0.5s ease forwards;
}

/* Fade-in-left animation */
.fade-in-left {
  opacity: 1;
  transform: translateX(0);
  animation: fadeInLeft 0.5s ease forwards;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@media (min-width: 1024px) {
  .scale-in, .fade-in, .fade-in-down, .fade-in-up, .fade-in-right, .fade-in-left, .section-fade-in-down {
    animation-delay: var(--delay-in, 0s);
}

.scale-out, .fade-out, .section-fade-out-up {
  animation-delay: var(--delay-out, 0s);
}
}

@keyframes fadeUp {
  from {
    transform: translateY(0); /* Start at the original position */
  }
  to {
    transform: translateY(-20px); /* Move up by 20px */
  }
}

.fade-up {
  animation: fadeUp 0.5s ease forwards; /* Smooth upward movement */
}

@keyframes fadeDown {
  from {
    transform: translateY(0); /* Start at the original position */
  }
  to {
    transform: translateY(20px); /* Move down by 20px */
  }
}

.fade-down {
  animation: fadeDown 0.5s ease forwards; /* Smooth downward movement */
}

}