/* contact-box.css */

/* Base contact box styles */
.contact-box {
    padding: 20px;
    /* background: var(--gradientBG1); */
    color: var(--maintext-color);
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  /* Inner content alignment */
  .contact-box-content {
    gap: 10px;
    /* align-items: center;
    justify-content: center; */
  }
  
  /* Icon styles */
  .contact-icon {
    font-size: 2rem; /* Adjust icon size */
    margin-bottom: 10px;
    color: var(--primary-color);
  }
  
  /* Label styles */
  .contact-label {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  /* Value styles */
  .contact-value {
    font-size: 1rem;
    color: var(--secondary-text-color);
  }
  
  /* Hover effects */
  .contact-box:hover {
    transform: scale(1.05);
    box-shadow: 0 0 10px var(--primary-color);
  }

  @media (max-width: 1024px) {
    .contact-value {
        font-size: .8rem;
    }
}
 