.styled-circle {
    background-color: transparent;
    border-radius: 50%;
    height: 150px;
    width: 150px;
    box-shadow: var(--complex-box-shadow), var(--complex-box-shadow) inset;
    transition: var(--primaryTransition);
}

.styled-img {
    /* height: 250px;
    width: 250px; */
    border-radius: 20px;
    box-shadow: var(--complex-box-shadow);
    transition: var(--primaryTransition);
}

