/* button.css */
.styled-button {
    padding: 20px 45px;
    border-radius: var(--border-radius);
    color: var(--maintext-color);
    cursor: pointer;
    background-color: transparent;
    border: var(--dynamicButtonHover);
    /* transition: background-color 0.45s, box-shadow 0.3s;  */
    width: max-content;
    line-height: var(--line-height-heading);
    letter-spacing: var(--letter-spacing-narrow);
    text-transform: uppercase;
}

.styled-button:hover {
    filter: var(--dropshadow1);
    color: var(--background-color);
    border: var(--dynamicButtonHover);
    background-color: var(--primary-color);
}