/* contact-us.css */
.contact-form {
    /* background-color: var(--transparentFade-bg2); */
   width: 100%;;
}

.form-group {
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap */
    width: 100%; /* Ensure the container takes the full width */
}

.name-fields, .contact-fields, .subject-message {
    width: -webkit-fill-available; /* Take up the full width */
} 

.subject-message {
    display: flex;
    flex-direction: column; /* Stack subject and message vertically */
}

.subject-message input,
.subject-message textarea {
    width: -webkit-fill-available; /* Take up the full width */
    flex-grow: 1;
}

/* Add horizontal spacing between input fields */
.name-fields input,
.contact-fields input {
    margin-right: var(--form-spacing); 
}

.name-fields input:last-child,
.contact-fields input:last-child {
    margin-right: 0; /* Remove margin for the last input */
}

.contact-info {
    flex-wrap: wrap; /* Allow items to wrap */
    gap: 20px; /* Adjust the gap between contact info items */
}

.contact-form button {
    padding: 25px 80px;
}

.contact-info-item {
    padding: 10px 0; /* Adjust the gap between icon and text */
    text-decoration: none;
    color: var(--maintext-color);
}

.contact-content {
    padding-left: 15px;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
    .contact-form {
        padding: 30px 0;
    }

    .contact-form button {
        width: -webkit-fill-available;
    }

    .name-fields,
    .contact-fields {
        flex-direction: column; /* Stack vertically only for mobile */
    }

    .name-fields input,
    .contact-fields input {
        margin-right: 0; /* Reset margin on mobile */
        margin-bottom: var(--form-spacing); /* Vertical spacing between subject and message */
    }

    .subject-message input,
    .subject-message textarea {
        margin-bottom: var(--form-spacing); /* Vertical spacing between subject and message */
    }
}

@media (min-width: 768px) {
    .subject-message input,
    .subject-message textarea {
        margin-bottom: var(--form-spacing); /* Vertical spacing between subject and message */
    }

    .subject-message {
        margin-bottom: var(--form-spacing); /* Vertical spacing before the submit button */
    }

    .name-fields, .contact-fields, .subject-message {
        margin-bottom: var(--form-spacing); /* Vertical spacing between fields */
    }

    .contact-sec-header {
        margin-right: 80px;
    }
}