/* project-item2.css */

.project-item {
    position: relative;
    /* width: 50%; */
    flex: 1 1 calc(50% - 20px); /* Two columns, with space between */
}
  
  .project-link {
    position: relative;
    display: block;
    overflow: hidden; /* Ensure the image doesn't overflow out of the container */
    transition: all 0.3s ease; /* Smooth transition */
    border-radius: calc(var(--border-radius)/ 2); 
  }
  
  .project-link:hover {
    /* Hover effect to scale the background image */
    transform: scale(1.05); /* Slight scaling effect */
  }
  
  .project-content {
    width: 100%;
    height: 100%;
    padding: var(--primary-padding);
  }
  
  .project-content h5,
  .project-content p {
    margin: 0;
  }
  
  .project-item-icon {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }

  .project-item-icon .arrow {
    margin-left: 10px;
    transition: transform 0.3s ease; /* Smooth scaling of the arrow */
  }
  
  .arrow, .project-item-icon:hover {
    animation: shakeRight 1s ease-in-out infinite; /* Apply shake animation on hover */
  }
  
  .project-item-onhover {
    display: none; /* Hidden by default */
  }
  
  .project-link:hover .project-item-onhover {
    display: block; /* Show on hover */
  }
  
  @media (max-width: 768px) {
    .project-item {
      flex: 1 1 100%; /* Single column layout for small screens */
    }
  }