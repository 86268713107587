/* section.css */
:root {
    --section-padding: 10em 0;
    }

    section {
        position: relative;
    }

    .section-padding {
        padding: var(--section-padding);
    }

.section-innerdiv {
    /* padding: 40px; */
}