/* primary-color-changer.css */

.primary-color-changer {
  position: relative;
}

.color-picker-modal {
  position: absolute;
  top: 100%; /* Adjust based on your layout */
  left: 0;
  margin-top: 0.5rem;
  display: flex;
  gap: 0.5rem;
  background: var(--secondary-bg);
  padding: 0.5rem;
  border-radius: 8px;
  z-index: 1000; /* Ensure it appears above other elements */
  overflow-x: auto; /* Enable horizontal scrolling */
}

.color-button {
  position: relative;
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: transform 0.2s ease;
  padding: 0;
  flex: 0 0 auto; /* Ensure buttons stay inline for scrolling */
}

.color-button:hover {
  transform: scale(1.1);
}

.default-color {
  display: flex;
  justify-content: center;
  align-items: center;
  border: var(--maintext-color) 2px solid;
}

.default-color:before {
  content: 'x';
}

.color-button {
  border: var(--maintext-color) 2px solid;
}

.current-color::before {
  content: 'x';
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .color-picker-modal {
    overflow-x: scroll; /* Enable horizontal scroll for mobile */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS devices */
    max-width: 50vw; /* Constrain width on mobile devices */
  }
}

@media (min-width: 768px) {
  .color-picker-modal {
    max-width: 90vw; /* Constrain width on mobile devices */
  }
}