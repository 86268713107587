/* hero1.css */
:root {
    --hero-height: calc(100vh - var(--header-height));
}

#hero-section {
    height: var(--hero-height);
}

.hero-left {
    z-index: 2;
}

.hero-left-container {
    /* text-align: left; */
} 

.hero-left h1 {
    
}

.hero-left-container span {
    font-size: var(--emphasized-hero-header-font-size);
}

.hero-right {
    z-index: 1;
}

@media (min-width: 1024px) {
    #hero-section {
        /* justify-content: center; */
    }
}

@media (max-width: 1024px) {
#hero-section {
    height: calc(var(--hero-height)*2);
    justify-content: space-evenly;
}

.hero-left p {
    margin-top: var(--primary-padding);
}
}

@media (min-width: 768px) {
    .hero-left-container h1 {
        padding: 5px 0;
    }

    .hero-left p {
        margin-top: 1pc;
    }
}

/* mobile view */
@media (max-width: 768px) {
    .hero-left-container h1 {
        padding: 15px 0;
    }

    .hero-left p {
        /* margin: 25px 30px 0 30px; */
    }

    .hero-left {
        /* padding-left: 25px; */
    }
}

@media (max-width: 495px) {
    #hero-section {
        height: calc(var(--hero-height)*1.6);
    }
}

.bottom-hero {
    position: absolute;
    bottom: 10%;
    right: 50%;
    left: 50%;
    color: var(--maintext-color);
}

.bottom-hero button {
    border-radius: 50%;
    padding: 5px;
    border-color: var(--maintext-color);
    box-shadow: none;
}