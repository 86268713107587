@keyframes scaleUp {
    0% {
      opacity: 0;
      transform: scale(0.5); /* Start smaller */
    }
    100% {
      opacity: 1;
      transform: scale(1); /* End at full size */
    }
  }
  
  .scale-up-char {
    display: inline-block;
    opacity: 0;
    animation: scaleUp 0.3s ease forwards; /* Duration for the scale-up effect */
  }


  